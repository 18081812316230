import Quotes from 'randomquote-api';
import { TOTAL_QUESTIONS, HIGHEST_NUMBER } from './../../../utils/constants';

let isFirstRun = true;

const OBJ_HELP = { type: 'system', label: `System`, content: `Type "help" to get a supporting command list.` };

const getTime = () => {
    const date = new Date()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return `${hours}${minutes < 10 ? ':0' : ':'}${minutes}${seconds < 10 ? ':0' : ':'}${seconds}`
}

const validURL = str => {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

const getNow = () => {
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
    const now = new Date();
    return now.toLocaleDateString("en-US", options) + " - " + now.toLocaleTimeString();
}

const introduction = [
    `Login: ${getNow()}`,
    {
        type: 'info',
        label: 'Info',
        content: `Hi, I'm Charisma!`
    },
    {
        type: 'info',
        label: 'Info',
        content: `I build things with code.`
    },
    {
        type: 'info',
        label: 'Info',
        content: `I create digital products.`
    },
    // {
    //     type: 'info',
    //     label: 'Info',
    //     content: `Innovation is my passion.`
    // }
]

let isQuizStarted = false;
let score = 0;
let quizStartTime = new Date();
let answers = [];
const operators = ['+', '-', 'x', ':', 'x', ':'];
let questions = [];
let level = 0;

function getRandomNumber(bottom, top) {
    return Math.floor(Math.random() * (top - bottom + 1) + bottom)
}

function generateQuestion(answer) {
    const operator = operators[Math.floor(Math.random() * operators.length)];
    if(operator == '+') {
        const temp = getRandomNumber(0, answer);
        return `${temp} ${operator} ${answer - temp}`;
    } else if(operator == '-') {
        const temp = getRandomNumber(answer, HIGHEST_NUMBER);
        return `${temp} ${operator} ${temp - answer}`
    } else if(operator == 'x') {
        let temp = [];
        for(let i = 2; i < answer; i++) {
            if(answer % i == 0)
                temp.push(i);
        }
        if(temp.length == 0) {
            return generateQuestion(answer);
        } else {
            const t = temp[Math.floor(Math.random() * temp.length)];
            return `${t} ${operator} ${answer / t}`;
        }
    } else {
        let temp = [];
        for(let i = answer + 1; i <= HIGHEST_NUMBER; i++) {
            if(i % answer == 0)
                temp.push(i);
        }
        if(temp.length == 0) {
            return generateQuestion(answer);
        } else {
            const t = temp[Math.floor(Math.random() * temp.length)];
            return `${t} ${operator} ${t / answer}`;
        }
    }
}

function resetQuiz() {
    isQuizStarted = true;
    quizStartTime = new Date();
    score = 0;
    level = 0;
    answers = [];
    questions = [];
    for(let i = 0; i < TOTAL_QUESTIONS; i++) {
        const tempQuestion = getRandomNumber(1, HIGHEST_NUMBER);
        answers.push(tempQuestion);
        questions.push(generateQuestion(tempQuestion));
    }
}

function secondsToHMS(secs) {
    function z(i){
        const n = Math.round(i);
        return (n<10?'0':'') + n;
    }
    const sign = secs < 0? '-':'';
    secs = Math.abs(secs);
    return sign + z(secs/3600 |0) + ':' + z((secs%3600) / 60 |0) + ':' + z(secs%60);
}

function getObjQuestion() {
    return {
        type: 'warning',
        label: 'Quiz',
        content: questions[level]
    };
}

function getObjScore() {
    return {
        type: 'system',
        label: 'System',
        content: `Question ${level + 1}/${TOTAL_QUESTIONS} (score: ${score})`
    };
}

let commands = {
    intro: {
        description: 'Introducting myself again.',
        run(print) {
            let i = isFirstRun ? 0 : 1;
            return new Promise(resolve => {
                const interval = setInterval(() => {
                    if(i > 0 || isFirstRun)
                        print(introduction[i])
                    i++
                    if (!introduction[i]) {
                        clearInterval(interval);
                        if(isFirstRun) {
                            // print({ type: 'system', label: 'System', content: 'Type "contact" to print a list of contact information.' });
                            // print({ type: 'system', label: 'System', content: 'Type "quote" to get a random quote from a collection of quotes.' });
                            isFirstRun = false;
                        }
                        resolve({ type: 'system', label: 'System', content: 'Type "contact" to print a list of contact information.' });
                    }
                }, 500)
            })
        }
    },
    open: {
        description: 'Open a specified url in a new tab.',
        run(print, input) {
            let tempInput = input;
            return new Promise((resolve) => {
                if(!input) {
                    resolve({ type: 'error', label: 'Error', content: 'a url is required!' })
                    return
                } else if(input.toLowerCase().trim() == 'email') {
                    tempInput = 'mailto:charismapribadi@gmail.com';
                } else if(input.toLowerCase().trim() == 'phone' || input.toLowerCase().trim() == 'wa' ||input.toLowerCase().trim() == 'whatsapp') {
                    tempInput = 'https://wa.me/628568833113';
                } else if(input.toLowerCase().trim() == 'telegram') {
                    tempInput = 'https://t.me/charismapribadi';
                } else if(input.toLowerCase().trim() == 'linkedin') {
                    tempInput = 'https://linkedin.com/in/charismapribadi';
                } else if(input.toLowerCase().trim() == 'open') {
                    resolve({ type: 'error', label: 'Error', content: `Please input a valid URL! Example: "open google.com" or "open telegram" to contact me directly.` })
                    return
                } else if (!validURL(input)) {
                    resolve({ type: 'error', label: 'Error', content: `"${input}" is not a valid URL!` })
                    return
                }

                if(validURL(input) && !input.startsWith('http'))
                    tempInput = `http://${input}`;

                window.open(tempInput, '_blank')
                resolve({ type: 'system', label: 'System', content: `Opening "${input}", please wait...` })
            })
        }
    },
    more: {
        description: 'Open linktr.ee/charismapribadi.',
        run(print) {
            return new Promise((resolve) => {
                window.open('https://linktr.ee/charismapribadi', '_self')
                resolve({ type: 'system', label: 'System', content: 'Opening "linktr.ee/charismapribadi", please wait...' })
            })
        }
    },
    quote: {
        description: 'Get a random quote from a collection of quotes.',
        run(print) {
            const quote = Quotes.randomQuote();
            return new Promise((resolve) => {
                resolve({ type: 'success', label: getTime(), content: `"${quote.quote}" - ${quote.author}` })
            })
        }
    },
    quiz: {
        description: 'Take a math quiz and challenge yourself!',
        run(print) {
            return new Promise((resolve) => {
                resetQuiz();
                print(getObjScore());
                resolve(getObjQuestion());
            })
        }
    },
    '##num##': {
        run(print, input) {
            return new Promise(resolve => {
                const isValidInput = input.length > 0 && !isNaN(input);
                if(!isQuizStarted || !isValidInput) {
                    print({ type: 'error', label: 'Error', content: `Command '${input}' not found` });
                    resolve(OBJ_HELP);
                }
                else {
                    const isCorrect = answers[level] == input;
                    print({
                        type: isCorrect ? 'success' : 'error',
                        label: isCorrect ? 'Correct' : 'Incorrect',
                        content: `${questions[level]} = ${answers[level]}`
                    });
                    if(isCorrect)
                        score += 10;
                    level += 1;
                    if(level < TOTAL_QUESTIONS) {
                        print(getObjScore());
                        resolve(getObjQuestion());
                    } else {
                        isQuizStarted = false;
                        const quizEndTime = new Date();
                        const time = secondsToHMS((quizEndTime - quizStartTime) / 1000);
                        print({
                            type: 'success',
                            label: 'Done',
                            content: `Score: ${score} [${score / TOTAL_QUESTIONS}/${TOTAL_QUESTIONS}] | Time: ${time}`
                        });
                        resolve(OBJ_HELP);
                    }
                }
            });
        }
    }
};

export default commands;