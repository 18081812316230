import './App.css';
import Terminal from './components/pribadi-terminal';
import dynamicList from './components/pribadi-terminal/commands/dynamic';
import staticList from './components/pribadi-terminal/commands/static';

const cmd = {
  dynamicList,
  staticList
};

const config = {
  prompt: '➜  ~ ',
  version: '1.0.0',
  initialDirectory: '~/www/charismapribadi',
  bootCmd: 'intro'
};

function App() {
  return <Terminal cmd={cmd} config={config} />;
};

export default App;
