export default {
    contact: {
        description: 'Return a list of contact information.',
        list: [
            {
                type: 'warning',
                label: 'Email',
                content: 'charismapribadi@gmail.com'
            },
            {
                type: 'warning',
                label: 'Phone',
                content: '(+62) 856 8833113'
            },
            {
                type: 'warning',
                label: 'Telegram',
                content: 't.me/charismapribadi'
            },
            {
                type: 'warning',
                label: 'LinkedIn',
                content: 'linkedin.com/in/charismapribadi'
            },
            {
                type: 'system',
                label: 'System',
                content: 'Type "open [email / phone / telegram / linkedin]" to contact me.'
            },
            {
                type: 'system',
                label: 'System',
                content: 'Type "more" to load more about me.'
            },
            {
                type: 'system',
                label: 'System',
                content: 'Type "help" to get a supporting command list.'
            }
        ]
    }
}